import { FC } from "react";
import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import Image from "next/legacy/image";
import Link from "next/link";
import img1 from "@/assets/bee-and-birds.png";
import img2 from "@/assets/man-in-hat.png";
import { ContainedButton } from "@/lib/components/contained-button";

interface ResourceSectionProps {
  landingPage?: boolean;
}

export const ResourceSection: FC<ResourceSectionProps> = ({ landingPage }) => {
  const mediumScreen = useMediaQuery("(max-width:600px)", {
    noSsr: true,
  });
  const resourceText = {
    fontSize: { xs: "24px", sm: "32px" },
    fontWeight: { xs: "300", sm: "900" },
    lineHeight: { xs: "26px", sm: "34px" },
    textAlign: "center",
    marginBottom: { xs: "27px", sm: "36px" },
  };
  const landingResourceText = {
    fontSize: "32px",
    fontWeight: "900",
    lineHeight: "34px",
    textAlign: "center",
    marginBottom: { xs: "19px", sm: "36px" },
  };
  const containerStyle = {
    paddingLeft: { xs: "25px" },
    paddingRight: { xs: "25px" },
    marginTop: { xs: "44px" },
    display: "flex",
    flexDirection: "column",
    alignItems: { sm: "center" },
  };
  const outlinedBoxLeft = {
    border: "1px solid #C2C2C2",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "space-between",
    marginBottom: { xs: "20px", sm: "36px" },
  };
  const outlinedBoxRight = {
    border: "1px solid #C2C2C2",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "space-between",
    marginBottom: { xs: "90px", sm: "100px", md: "208px" },
  };
  const titleTextMobile = {
    display: { xs: "flex", sm: "flex" },
    fontSize: { xs: "28px", sm: "32px" },
    fontWeight: "900",
    lineHeight: { xs: "34px", sm: "32px" },
    marginTop: { xs: "25px", sm: "45px", md: "98px" },
    marginBottom: { xs: "5px", sm: "34px" },
    textAlign: "left",
    color: "#163D23",
    paddingLeft: { xs: "25px", sm: "50px", md: "80px" },
    paddingRight: { xs: "25px", md: "120px" },
  };

  const descriptionTextMobile = {
    display: { xs: "flex", sm: "flex" },

    fontSize: { sx: "16px", sm: "18px" },
    fontWeight: "400",
    lineHeight: { xs: "24px", sm: "26px" },
    color: "#414042",
    textAlign: "left",
    paddingLeft: { xs: "25px", sm: "50px", md: "80px" },
    paddingRight: { xs: "25px", md: "120px" },
  };
  const buttonStyle = {
    color: "white",
    background: "#006EA8",
    boxSizing: "border-box",
    border: "2px solid #006EA8",
    borderRadius: "25px",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "19.2px",
    paddingLeft: "54px",
    paddingRight: "54px",
    paddingTop: "13px",
    paddingBottom: "13px",
    textTransform: "none",
    marginTop: { xs: "20px", sm: "34px" },
    marginBottom: { xs: "41px", sm: "45px", md: "97px" },
    marginLeft: { xs: "25px", sm: "50px", md: "80px" },
  };
  return (
    <Container data-testid="resource-section" sx={containerStyle}>
      <Typography data-testid="title-text" sx={landingPage ? landingResourceText : resourceText}>
        Helpful resources
      </Typography>
      <Grid container sx={outlinedBoxLeft}>
        <Grid item xs={12} sm={7}>
          <Typography data-testid="subtitle-1-text-mobile" sx={titleTextMobile}>
            (Re)Gain the benefits of healthy hearing.
          </Typography>
          <Typography data-testid="description-1-text-mobile" sx={descriptionTextMobile}>
            From the rhythmic roar of ocean waves to the crackle and hiss of fireworks overhead, hearing creates a connection to the world
            around us.
          </Typography>
          <Link href="https://www.truhearing.com/hearing-health/" passHref target="_blank" style={{ textDecoration: "none" }}>
            <ContainedButton data-testid="explore-survey-button" sx={buttonStyle}>
              Check your hearing
            </ContainedButton>
          </Link>
        </Grid>
        {!mediumScreen && (
          <Grid item xs={0} sm={5} sx={{ position: "relative" }}>
            <Image layout="fill" objectFit="cover" data-testid="image-1" src={img1} alt="book" />
          </Grid>
        )}
      </Grid>
      <Grid container sx={outlinedBoxRight}>
        <Grid item xs={12} sm={7}>
          <Typography data-testid="subtitle-2-text-mobile" sx={titleTextMobile}>
            Noise Induced Hearing Loss Is Bigger Than You Think
          </Typography>
          <Typography data-testid="description-2-text-mobile" sx={descriptionTextMobile}>
            Noise induced hearing loss (NIHL) occurs when we are exposed to harmful noise levels such as sounds that are too loud or loud
            sounds heard for too long.
          </Typography>

          <Link
            href="https://www.truhearing.com/articles/noise-induced-hearing-loss-is-bigger-than-you-think/"
            passHref
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <ContainedButton data-testid="survey-button" sx={buttonStyle}>
              Read article
            </ContainedButton>
          </Link>
        </Grid>
        {!mediumScreen && (
          <Grid item xs={0} sm={5} sx={{ position: "relative" }}>
            <Image layout="fill" objectFit="cover" data-testid="image-2" src={img2} alt="book" />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
