import { FC, useState } from "react";
import { Container, Grid, Link, Typography, useMediaQuery } from "@mui/material";
import Image from "next/legacy/image";
import img1 from "@/assets/man-in-suit-desktop.png";
import img2 from "@/assets/man-in-suit-mobile.png";
import img3 from "@/assets/man-in-suit-mobile-left-flush-new.png";
import { getShouldShowQuestionnaire, setSkippedQuestionnaire } from "@/redux/user";
import { QuestionnaireDialog } from "@/features/questionnaire/questionnaire-dialog";
import { OutlinedButton } from "@/lib/components/outlined-button";
import { useAppDispatch, useAppSelector } from "@/lib/redux-hooks";

interface QuizSectionProps {
  landingPage?: boolean;
}

export const QuizSection: FC<QuizSectionProps> = ({ landingPage }) => {
  const dispatch = useAppDispatch();
  const shouldShowQuestionnaire = useAppSelector(getShouldShowQuestionnaire);
  const [takingQuiz, setTakingQuiz] = useState(false);
  const mobileScreen = useMediaQuery("(max-width:600px)", {
    noSsr: true,
  });
  const containerStyle = {
    paddingLeft: { xs: "25px", sm: "40px" },
    paddingRight: { xs: "25px", sm: "40px" },
    marginTop: { xs: "45px" },
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    alignItems: { sm: "center" },
  };
  const titleText = {
    fontSize: "32px",
    fontWeight: "900",
    lineHeight: "34px",
    marginTop: { xs: "20px", sm: "0px" },
    marginBottom: "10px",
    textAlign: "left",
    color: "#163D23",
  };
  const descriptionText = {
    fontSize: { sx: "16px", sm: "18px" },
    fontWeight: "400",
    lineHeight: { xs: "24px", sm: "26px" },
    color: "#313133",
  };
  const buttonStyle = {
    color: "#006CA6",
    boxSizing: "border-box",
    border: "2px solid #006CA6",
    borderRadius: "25px",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "26px",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingTop: "10px",
    paddingBottom: "10px",
    textTransform: "none",
    marginTop: "22px",
    marginBottom: "22px",
  };

  const shouldDisplayQuestionnaireDialog = shouldShowQuestionnaire && takingQuiz;
  return (
    <>
      {mobileScreen && landingPage && (
        <Grid container sx={{ position: "relative", marginTop: "-45px", paddingRight: "65px" }}>
          <Image data-testid={"mobile-image-left-flush"} src={img3}></Image>
        </Grid>
      )}
      <Container data-testid="quiz-section" sx={containerStyle}>
        <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: { xs: landingPage ? "flex-start" : "center" } }}>
          {(!landingPage || !mobileScreen) && (
            <Image data-testid={mobileScreen ? "mobile-image" : "desktop-image"} src={mobileScreen ? img2 : img1}></Image>
          )}
        </Grid>
        <Grid item xs={12} sm={6} sx={{ paddingLeft: { sm: "55px" }, maxWidth: { sm: "350px", md: "564px" } }}>
          <Typography data-testid="title-text" sx={titleText}>
            Are OTC hearing aids right for me?
          </Typography>
          <Typography data-testid="description-text" sx={descriptionText}>
            Take TruHearing’s online quiz to identify your hearing needs and determine what hearing solution may be right for you.
          </Typography>
          <OutlinedButton
            onClick={() => {
              setTakingQuiz(true);
              dispatch(setSkippedQuestionnaire(false));
            }}
            data-testid="start-quiz-button"
            variant="outlined"
            sx={buttonStyle}
          >
            Start quiz
          </OutlinedButton>
          {/* TODO: Click here functionality */}
          <Typography data-testid="link-text" sx={descriptionText}>
            Looking for an online hearing screener? Grab your headphones, then{" "}
            <Link
              href="https://www.shoeboxonline.com/truhearing1/?"
              target="_blank"
              sx={{ color: "#2B6BA1", textDecoration: "none", cursor: "pointer" }}
            >
              click here
            </Link>
            .
          </Typography>
        </Grid>
        {shouldDisplayQuestionnaireDialog && <QuestionnaireDialog skipTakeQuizQuestion={true} />}
      </Container>
    </>
  );
};
