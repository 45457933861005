import { FC } from "react";
import { Container, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { MobileComparisonChart } from "@/features/landing-page/mobile-comparison-chart";
import { DesktopComparisonChart } from "@/features/landing-page/desktop-comparison-chart";

export const ComparisonSection: FC = () => {
  const mobileScreen = useMediaQuery("(max-width:600px)", {
    noSsr: true,
  });
  const titleText = {
    fontSize: "32px",
    fontWeight: { xs: "300", sm: "900" },
    lineHeight: "34px",
    paddingTop: { xs: "40px", sm: "58px" },
    color: "#163D23",
    textAlign: { sm: "center" },
  };
  const otcTitle = {
    fontSize: "28px",
    fontWeight: 900,
    lineHeight: "34px",
    paddingBottom: "17px",
    paddingLeft: { sm: "30px" },
    color: "#163D23",
  };
  const prescriptionTitle = {
    fontSize: "28px",
    fontWeight: 900,
    lineHeight: "26px",
    paddingBottom: "30px",
    paddingTop: { xs: "5px", sm: "0px" },
    color: "#163D23",
  };
  const descriptionText = {
    fontSize: { xs: "16px" },
    fontWeight: { xs: "400" },
    lineHeight: { xs: "24px" },
    color: "##414042",
    marginBottom: { xs: "30px" },
    paddingRight: { sm: "20px" },
  };
  const otcDescriptionText = {
    fontSize: { xs: "16px" },
    fontWeight: { xs: "400" },
    lineHeight: { xs: "24px" },
    paddingLeft: { sm: "30px" },
    color: "##414042",
    marginBottom: { xs: "30px" },
  };
  const comparisonBold = {
    fontSize: { xs: "18px", sm: "24px" },
    fontWeight: { xs: "900", sm: "300" },
    lineHeight: { xs: "21.8px", sm: "26px" },
    paddingTop: { xs: "10px" },
    paddingBottom: { xs: "16px" },
    color: "#163D23",
    textAlign: { xs: "center", sm: "left" },
    paddingLeft: { sm: "30px" },
  };
  const comparisonBoldNoLeftPadding = {
    fontSize: { xs: "18px", sm: "24px" },
    fontWeight: { xs: "900", sm: "300" },
    lineHeight: { xs: "21.8px", sm: "26px" },
    paddingTop: { xs: "10px" },
    paddingBottom: { xs: "16px" },
    color: "#163D23",
    textAlign: { xs: "center", sm: "left" },
  };
  const containerStyle = {
    paddingLeft: { xs: "25px" },
    paddingRight: { xs: "25px" },
  };
  const dividerStyle = {
    display: { xs: "none", sm: "flex" },
    marginLeft: { sm: "40px" },
    marginRight: { sm: "40px" },
  };

  return (
    <Container data-testid="comparison-section" maxWidth="md" sx={containerStyle}>
      <Typography data-testid="comparison-title" sx={titleText}>
        OTC hearing aids or Prescription? <br />
        Which is right for you.
      </Typography>
      <Grid container sx={{ justifyContent: "center", marginTop: "35px" }}>
        <Grid item xs={12} sm={5}>
          <Typography data-testid="comparison-sub-title-1" sx={otcTitle}>
            Over-the-Counter <br />
            (OTC)
          </Typography>
          <Typography data-testid="comparison-description-1" sx={otcDescriptionText}>
            OTC hearing aids are designed to address mild to moderate hearing loss. They are available without a prescription and a visit to
            a hearing professional, so they require self-service to set-up and customize.
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem sx={dividerStyle}></Divider>
        <Grid item xs={12} sm={5}>
          <Typography data-testid="comparison-sub-title-2" sx={prescriptionTitle}>
            Prescription
          </Typography>
          <Typography data-testid="comparison-description-2" sx={descriptionText}>
            Prescription hearing aids have more advanced technology and address all levels of hearing loss, from mild to profound. You
            benefit from a visit to a licensed hearing professional to perform an advanced hearing exam, who will set-up and ensure devices
            are personalized to a patient’s hearing loss.
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid data-testid="comparison-category-1" item xs={5}>
          {mobileScreen ? (
            <Typography data-testid="category-1-title-mobile" sx={comparisonBold}>
              OTC <br /> Features:
            </Typography>
          ) : (
            <Typography data-testid="category-1-title-desktop" sx={comparisonBold}>
              Features:
            </Typography>
          )}
        </Grid>
        <Divider orientation="vertical" flexItem sx={dividerStyle}></Divider>
        <Grid data-testid="comparison-category-2" item xs={5}>
          {mobileScreen ? (
            <Typography data-testid="category-2-title-mobile" sx={comparisonBold}>
              Prescription
              <br /> Features:
            </Typography>
          ) : (
            <Typography data-testid="category-2-title-desktop" sx={comparisonBoldNoLeftPadding}>
              Features:
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container sx={{ justifyContent: "center" }}>
        {mobileScreen && <MobileComparisonChart />}
        {!mobileScreen && <DesktopComparisonChart />}
      </Grid>
    </Container>
  );
};
