import { FC } from "react";
import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import Image from "next/legacy/image";
import Link from "next/link";
import img1 from "@/assets/man-getting-tested.png";
import img2 from "@/assets/two-people-talking.png";
import { setSkippedQuestionnaire } from "@/redux/user";
import { ContainedButton } from "@/lib/components/contained-button";
import { useAppDispatch } from "@/lib/redux-hooks";

interface ConsultationSectionProps {
  landingPage?: boolean;
}

export const ConsultationSection: FC<ConsultationSectionProps> = ({ landingPage }) => {
  const dispatch = useAppDispatch();
  const smallScreen = useMediaQuery("(max-width:1200px)", {
    noSsr: true,
  });
  const containerStyle = {
    paddingLeft: { xs: "25px" },
    paddingRight: { xs: "25px" },
    marginTop: { xs: "90px" },
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    alignItems: { sm: "center" },
  };
  const outlinedBoxLeft = {
    border: "1px solid #C2C2C2",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "15px",
  };
  const outlinedBoxRight = {
    border: "1px solid #C2C2C2",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "15px",
    marginLeft: { sm: "15px" },
  };
  const titleText = {
    fontSize: { xs: "32px", sm: "28px", md: "32px" },
    fontWeight: "900",
    lineHeight: "34px",
    marginTop: { xs: "10px", sm: "15px" },
    marginBottom: "10px",
    textAlign: "center",
    color: "#163D23",
    paddingLeft: { xs: "20px", sm: "0px" },
    paddingRight: { xs: "20px", sm: "0px" },
  };

  const descriptionText = {
    fontSize: { sx: "16px", sm: "18px" },
    fontWeight: "400",
    lineHeight: { xs: "24px", sm: "26px" },
    color: "#414042",
    textAlign: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
  };
  const buttonStyle = {
    color: "white",
    background: "#006EA8",
    boxSizing: "border-box",
    border: "2px solid #006EA8",
    borderRadius: "25px",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "19.2px",
    paddingLeft: { xs: "54px", sm: "30px", md: "54px" },
    paddingRight: { xs: "54px", sm: "30px", md: "54px" },
    paddingTop: "13px",
    paddingBottom: "13px",
    textTransform: "none",
    textDecoration: "none",
  };
  return (
    <Container data-testid="consultation-section" sx={containerStyle}>
      <Grid item xs={12} sm={6} sx={outlinedBoxLeft}>
        <Grid item sx={{ position: "relative" }}>
          <Image data-testid="image-1" src={img1} objectFit="cover"></Image>
        </Grid>
        {smallScreen ? (
          <Typography data-testid="title-1-text-smallScreen" sx={titleText}>
            Prescription
            <br /> hearing aids
          </Typography>
        ) : (
          <Typography data-testid="title-1-text-desktop" sx={titleText}>
            Prescription hearing aids
          </Typography>
        )}
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: { xs: "26px", sm: "68px" },
            height: { xs: "130px", sm: "155px", md: "130px" },
            alignItems: "center",
          }}
        >
          <Typography data-testid="description-1-text" sx={descriptionText}>
            Expert diagnosis and personal care from a hearing healthcare specialist near you.
          </Typography>
          <Link href="https://www.truhearing.com/how-it-works/" passHref target="_blank" style={{ textDecoration: "none" }}>
            <ContainedButton data-testid="schedule-consultation-button" sx={buttonStyle}>
              Schedule a consultation
            </ContainedButton>
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} sx={outlinedBoxRight}>
        <Grid item sx={{ position: "relative" }}>
          <Image data-testid="image-2" src={img2} objectFit="cover"></Image>
        </Grid>

        {smallScreen ? (
          <Typography data-testid="title-2-text-smallScreen" sx={titleText}>
            Over-the-counter
            <br /> (OTC) hearing aids
          </Typography>
        ) : (
          <Typography data-testid="title-2-text-desktop" sx={titleText}>
            Over-the-counter (OTC) hearing aids
          </Typography>
        )}
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginBottom: { xs: "26px", sm: "68px" },
            height: { xs: "130px", sm: "155px", md: "130px" },
            alignItems: "center",
          }}
        >
          <Typography data-testid="description-2-text" sx={descriptionText}>
            Hearing aids delivered directly to your door from experienced and reputable brands.{" "}
          </Typography>
          <ContainedButton
            href="/partners"
            onClick={() => {
              dispatch(setSkippedQuestionnaire(false));
            }}
            data-testid="products-button"
            sx={buttonStyle}
          >
            {landingPage ? `Browse OTC products` : `Browse products`}
          </ContainedButton>
        </Grid>
      </Grid>
    </Container>
  );
};
