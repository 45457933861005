import { FC } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import Image from "next/legacy/image";
import CheckIcon from "@/assets/Vector.svg";

export const MobileComparisonChart: FC = () => {
  const comparisonText = {
    fontSize: { xs: "16px", md: "24px" },
    fontWeight: { xs: "300", md: "300" },
    lineHeight: { xs: "21.8px", md: "26px" },
    textAlign: { xs: "center", md: "left" },
    color: "#313133",
  };

  return (
    <>
      {/* Budget Friendly mobile */}
      <Grid
        data-testid="mobile-comparison-chart"
        sx={{ width: "320px", marginBottom: "15px", backgroundColor: "#F1F1F3", borderRadius: "5px 5px 0px 0px", boxShadow: "none" }}
      >
        <Typography data-testid="budget-friendly-text" sx={{ textAlign: "center", marginBottom: "10px", marginTop: "15px" }}>
          Budget friendly options:
        </Typography>
        <Grid
          sx={{
            display: "flex",
            height: "50px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "17px",
          }}
        >
          <div style={{ height: "45px", width: "45px" }}>
            <Image data-testid="check-image" src={CheckIcon}></Image>
          </div>
          <Divider orientation="vertical" flexItem sx={{ marginLeft: "60px", marginRight: "60px" }}></Divider>
          <div style={{ height: "45px", width: "45px" }}>
            <Image data-testid="check-image" src={CheckIcon}></Image>
          </div>
        </Grid>
      </Grid>
      {/* fitting/programming mobile */}
      <Grid sx={{ width: "320px" }}>
        <Typography data-testid="fitting-programming-text" sx={{ textAlign: "center", marginBottom: "10px" }}>
          fitting/programming
        </Typography>
        <Grid
          sx={{
            display: "flex",
            height: "50px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "17px",
          }}
        >
          <Grid item xs={5}>
            {" "}
            <Typography data-testid="self-programmed-text" sx={comparisonText}>
              Self-programmed
              <br />
              with app
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ marginLeft: "10px", marginRight: "10px" }}></Divider>
          <Grid item xs={5}>
            {" "}
            <Typography data-testid="professional-programmed-text" sx={comparisonText}>
              Professionally
              <br /> programmed
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* examination mobile */}
      <Grid sx={{ width: "320px", marginBottom: "15px", backgroundColor: "#F1F1F3", borderRadius: "5px 5px 0px 0px", boxShadow: "none" }}>
        <Typography data-testid="examination-text" sx={{ textAlign: "center", marginBottom: "10px", marginTop: "15px" }}>
          Examination
        </Typography>
        <Grid
          sx={{
            display: "flex",
            height: "50px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "17px",
          }}
        >
          <Grid item xs={5}>
            {" "}
            <Typography data-testid="assessment-with-app-text" sx={comparisonText}>
              Hearing assessment
              <br />
              with app
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ marginLeft: "10px", marginRight: "10px" }}></Divider>
          <Grid item xs={5}>
            {" "}
            <Typography data-testid="assessment-in-person-text" sx={comparisonText}>
              In-person
              <br /> examination
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* hearing aid app mobile */}
      <Grid sx={{ width: "320px" }}>
        <Typography data-testid="hearing-aid-text" sx={{ textAlign: "center", marginBottom: "10px" }}>
          Hearing aid app
        </Typography>
        <Grid
          sx={{
            display: "flex",
            height: "50px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "17px",
          }}
        >
          <div style={{ height: "45px", width: "45px" }}>
            <Image data-testid="check-image" src={CheckIcon}></Image>
          </div>
          <Divider orientation="vertical" flexItem sx={{ marginLeft: "60px", marginRight: "60px" }}></Divider>

          <div style={{ height: "45px", width: "45px" }}>
            <Image data-testid="check-image" src={CheckIcon}></Image>
          </div>
        </Grid>
      </Grid>
      {/* hearing accessories mobile */}
      <Grid sx={{ width: "320px", marginBottom: "15px", backgroundColor: "#F1F1F3", borderRadius: "5px 5px 0px 0px", boxShadow: "none" }}>
        <Typography data-testid="hearing-accessories-text" sx={{ textAlign: "center", marginBottom: "10px", marginTop: "15px" }}>
          Hearing accessories:
        </Typography>
        <Grid
          sx={{
            display: "flex",
            height: "50px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "17px",
          }}
        >
          <Grid item xs={5}>
            {" "}
            <Typography data-testid="none-text" sx={comparisonText}>
              -
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ marginLeft: "10px", marginRight: "10px" }}></Divider>
          <Grid item xs={5}>
            {" "}
            <Typography data-testid="tv-streamer-text" sx={comparisonText}>
              TV Streamer
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* Repair and loss/damage warranty mobile */}
      <Grid sx={{ width: "320px" }}>
        <Typography data-testid="repair-text" sx={{ textAlign: "center", marginBottom: "10px" }}>
          Repair and loss/damage warranty
        </Typography>
        <Grid
          sx={{
            display: "flex",
            height: "50px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "17px",
          }}
        >
          <Grid item xs={5}>
            {" "}
            <Typography data-testid="1-year-text" sx={comparisonText}>
              1 year
              <br />
              (defects only)
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ marginLeft: "10px", marginRight: "10px" }}></Divider>
          <Grid item xs={5}>
            {" "}
            <Typography data-testid="3-year-text" sx={comparisonText}>
              3 year
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* Risk-free trial mobile */}
      <Grid sx={{ width: "320px", marginBottom: "15px", backgroundColor: "#F1F1F3", borderRadius: "5px 5px 0px 0px", boxShadow: "none" }}>
        <Typography data-testid="risk-free-text" sx={{ textAlign: "center", marginBottom: "10px", marginTop: "15px" }}>
          Risk-free trial
        </Typography>
        <Grid
          sx={{
            display: "flex",
            height: "50px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "17px",
          }}
        >
          <Grid item xs={5}>
            {" "}
            <Typography data-testid="45-day-text" sx={comparisonText}>
              45-day
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ marginLeft: "10px", marginRight: "10px" }}></Divider>
          <Grid item xs={5}>
            {" "}
            <Typography data-testid="60-day-text" sx={comparisonText}>
              60-day
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
