import { FC } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import Image from "next/legacy/image";
import CheckIcon from "@/assets/Vector.svg";

export const DesktopComparisonChart: FC = () => {
  const comparisonText = {
    fontSize: { xs: "16px", md: "24px" },
    fontWeight: { xs: "300", md: "300" },
    lineHeight: { xs: "21.8px", md: "26px" },
    textAlign: { xs: "center", sm: "left" },
    color: "#313133",
    marginLeft: "20px",
  };
  const dividerStyle = {
    display: { xs: "none", sm: "flex" },
    marginLeft: { sm: "10px" },
    marginRight: { sm: "10px" },
    // marginTop: "35px",
  };
  const grayBox = {
    height: "104px",
    // width: "380px",
    backgroundColor: "#F1F1F3",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "30px",
    marginBottom: "10px",
    paddingRight: "10px",
  };
  const grayBoldBox = {
    height: "104px",
    backgroundColor: "#F1F1F3",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "30px",
    paddingRight: "30px",
    marginBottom: "10px",
  };

  const whiteBox = {
    height: "104px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
  };
  const boldedText = { fontSize: "32px", fontWeight: "900", lineHeight: "34px" };

  return (
    <>
      <Grid data-testid="desktop-comparison-chart" item xs={5.5} sx={grayBox}>
        <div style={{ height: "45px", width: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Image data-testid="check-image" src={CheckIcon} alt="check mark"></Image>
        </div>
        <Typography data-testid="budget-friendly-text" sx={comparisonText}>
          Budget friendly options
        </Typography>
      </Grid>
      <Divider orientation="vertical" flexItem sx={dividerStyle}></Divider>
      <Grid item xs={5.5} sx={grayBox}>
        <div style={{ height: "45px", width: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Image data-testid="check-image" src={CheckIcon} alt="check mark"></Image>
        </div>{" "}
        <Typography data-testid="budget-friendly-text" sx={comparisonText}>
          Budget friendly options
        </Typography>
      </Grid>
      <Grid item xs={5.5} sx={grayBox}>
        <div style={{ height: "45px", width: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Image data-testid="check-image" src={CheckIcon} alt="check mark"></Image>
        </div>{" "}
        <Typography data-testid="self-programmed-text" sx={comparisonText}>
          Self-programmed with app
        </Typography>
      </Grid>
      <Divider orientation="vertical" flexItem sx={dividerStyle}></Divider>
      <Grid item xs={5.5} sx={grayBox}>
        <div style={{ height: "45px", width: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Image data-testid="check-image" src={CheckIcon} alt="check mark"></Image>
        </div>{" "}
        <Typography data-testid="professional-programmed-text" sx={comparisonText}>
          Professionally programmed
        </Typography>
      </Grid>
      <Grid item xs={5.5} sx={grayBox}>
        <div style={{ height: "45px", width: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Image data-testid="check-image" src={CheckIcon} alt="check mark"></Image>
        </div>{" "}
        <Typography data-testid="assessment-with-app-text" sx={comparisonText}>
          Hearing assessment with app
        </Typography>
      </Grid>
      <Divider orientation="vertical" flexItem sx={dividerStyle}></Divider>
      <Grid item xs={5.5} sx={grayBox}>
        <div style={{ height: "45px", width: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Image data-testid="check-image" src={CheckIcon} alt="check mark"></Image>
        </div>{" "}
        <Typography data-testid="assessment-in-person-text" sx={comparisonText}>
          In-person examination
        </Typography>
      </Grid>
      <Grid item xs={5.5} sx={grayBox}>
        <div style={{ height: "45px", width: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Image data-testid="check-image" src={CheckIcon} alt="check mark"></Image>
        </div>{" "}
        <Typography data-testid="hearing-aid-app-text" sx={comparisonText}>
          Hearing aid app
        </Typography>
      </Grid>
      <Divider orientation="vertical" flexItem sx={dividerStyle}></Divider>
      <Grid item xs={5.5} sx={grayBox}>
        <div style={{ height: "45px", width: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Image data-testid="check-image" src={CheckIcon} alt="check mark"></Image>
        </div>{" "}
        <Typography data-testid="hearing-aid-app-text" sx={comparisonText}>
          Hearing aid app
        </Typography>
      </Grid>
      <Grid item xs={5.5} sx={whiteBox}>
        <Typography data-testid="none-text" sx={comparisonText}>
          -
        </Typography>
      </Grid>
      <Divider orientation="vertical" flexItem sx={dividerStyle}></Divider>
      <Grid item xs={5.5} sx={grayBox}>
        <div style={{ height: "45px", width: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Image data-testid="check-image" src={CheckIcon} alt="check mark"></Image>
        </div>{" "}
        <Typography data-testid="hearing-accessories-text" sx={comparisonText}>
          Hearing accessories:
          <br />
          TV streamer
        </Typography>
      </Grid>
      <Grid item xs={5.5} sx={grayBoldBox}>
        <Typography data-testid="1-year-text" sx={boldedText}>
          1 year
        </Typography>
        <Typography data-testid="repair-text" sx={comparisonText}>
          Defects only
        </Typography>
      </Grid>
      <Divider orientation="vertical" flexItem sx={dividerStyle}></Divider>
      <Grid item xs={5.5} sx={grayBoldBox}>
        <Typography data-testid="3-year-text" sx={boldedText}>
          3 year
        </Typography>
        <Typography data-testid="repair-text" sx={comparisonText}>
          Repair and loss/damage warranty
        </Typography>
      </Grid>
      <Grid item xs={5.5} sx={grayBoldBox}>
        <Typography data-testid="45-day-text" sx={boldedText}>
          45-day
        </Typography>
        <Typography data-testid="risk-free-text" sx={comparisonText}>
          Risk-free trial
        </Typography>
      </Grid>
      <Divider orientation="vertical" flexItem sx={dividerStyle}></Divider>
      <Grid item xs={5.5} sx={grayBoldBox}>
        <Typography data-testid="60-day-text" sx={boldedText}>
          60-day
        </Typography>
        <Typography data-testid="risk-free-text" sx={comparisonText}>
          Risk-free trial
        </Typography>
      </Grid>
    </>
  );
};
